import './Styles/MessageCard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Twemoji } from 'react-emoji-render';
import { ExternalMedia, InternalMedia, Media, Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { useEffect, useState } from 'react';
import { useBackend } from '../Hooks/UseBackend';
import { MediaSource } from '@sodamnfoolish/sjc-backend-types/src/api/feed/media-source';
import { toast } from 'react-toastify';
import { useUrlParams } from '../Hooks/useUrlParams';
import pumpFunApi from '../utils/pumpfun/pump-fun-api';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import { DeployRequest } from '@sodamnfoolish/sjc-backend-types/src/api/wallet/dto';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { IMAGE } from '../utils/source-constants';
import { base64ToFile } from '../utils/base64-to-file';
import { Platform } from '../post-interface';

const MessageCard = ({
    post,
    background,
    onImageClick,
    platform,
    username,
    isGeneralPost,
    icon,
    className,
    solInitialBuyThunder,
    autoSellDelayThunder,
    autoSellProfitThunder,
    buyJitoTip,
    buyPrio,
}: {
    post: Post;
    background: string;
    onImageClick?: (index: number) => void;
    platform: string;
    solInitialBuyThunder: number;
    autoSellDelayThunder: number;
    autoSellProfitThunder: number;
    buyJitoTip: number;
    buyPrio: number;
    username?: string;
    isGeneralPost?: boolean;
    icon?: string;
    className?: string;
}) => {
    const { walletAddress } = useUrlParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { getMedia, getChatGptMetadataFromPostId, deployToken } = useBackend();
    const [imageSrcs, setImageSrcs] = useState<string[]>([]);
    const isOnTokenCreate = location.pathname === '/TokenCreate';
    const isOnResultPage = location.pathname === '/Result';

    useEffect(() => {
        const fetchImages = async () => {
            if (post.media && post.media.length > 0) {
                console.log('post.media', post.media);
                const mediaPromises = post.media.map((mediaItem: Media) => {
                    if (mediaItem.source === MediaSource.Internal) {
                        return getMedia((mediaItem as InternalMedia).id)
                            .then((blob) => URL.createObjectURL(blob))
                            .catch((error) => {
                                console.error('Error fetching internal media:', error);
                                return null;
                            });
                    } else if (mediaItem.source === MediaSource.External) {
                        return Promise.resolve((mediaItem as ExternalMedia).url);
                    }
                });

                const images = await Promise.all(mediaPromises);
                setImageSrcs(images.filter(Boolean) as string[]);
            }
        };

        fetchImages();
    }, []);

    const formatTime = (date: Date) => {
        const day = date.getDate();
        const year = date.getFullYear();
        const hours24 = date.getHours();
        const hours12 = hours24 % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = hours24 >= 12 ? 'PM' : 'AM';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        return `${hours12}:${minutes} ${amPm} - ${month} ${day}, ${year}`;
    };

    const displayText = (text: string, fullstoryUrl: string | null) => {
        if (!text) return null;

        const emoji = /:(0|1|2|3|4|5|6|7|8|9|10):/g;
        text = text.replaceAll('*', '');

        const fullStoryParts = text.split('Full Story');

        return fullStoryParts.map((fullStoryPart, fsIndex) => {
            const parts = fullStoryPart.split(emoji);

            return parts
                .map((part, index) => {
                    const trimmedPart = part.trim();
                    if (index % 2 !== 0) {
                        return <span key={`${fsIndex}-${index}`}>:{part}:</span>;
                    }

                    return trimmedPart ? (
                        <span key={`${fsIndex}-${index}`}>
                            <Twemoji text={trimmedPart} />
                        </span>
                    ) : null;
                })
                .concat(
                    fsIndex < fullStoryParts.length - 1 && fullstoryUrl ? (
                        <a href={fullstoryUrl} target='_blank' rel='noopener noreferrer' key={`fullstory-${fsIndex}`}>
                            Full Story
                            <p></p>
                        </a>
                    ) : null,
                );
        });
    };

    async function handleAiDeployment(post: Post) {
        try {
            if (walletAddress) {
                toast.info('Deploying ...');
                const metadata = await getChatGptMetadataFromPostId(post.id);

                let imageFile: File | null = null;
                if (post.media.length > 0) {
                    const imageUrl = (post.media[0] as Api.Feed.DTO.ExternalMedia).url;
                    async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
                        const response = await fetch(url);
                        const blob = await response.blob();
                        return new File([blob], filename, { type: mimeType });
                    }
                    imageFile = await urlToFile(imageUrl, 'image.png', 'image/png');
                } else {
                    function getSourceImage() {
                        const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
                            return base[platform] && (base[platform][post.username as keyof T] as string);
                        };

                        const image = getDetails(IMAGE, platform);

                        return image;
                    }
                    const dataUrl = getSourceImage();
                    const base64Image = `data:image/jpeg;base64,${dataUrl}`;
                    imageFile = base64ToFile(base64Image, 'tokenImage.jpg');
                }
                console.log('imageFile', imageFile);

                const chatGptMetadataResponse = await getChatGptMetadataFromPostId(post.id);
                const uploadMetadataResponse = await pumpFunApi.uploadMetadata({
                    file: imageFile,
                    name: chatGptMetadataResponse.name,
                    symbol: chatGptMetadataResponse.symbol,
                    description: post.url,
                    twitter: post.url,
                    telegram: '',
                    website: post.url,
                    showName: 'true',
                });

                const deployRequest: DeployRequest = {
                    ...metadata,
                    metadataUri: uploadMetadataResponse.metadataUri,
                    solAmount: solInitialBuyThunder * LAMPORTS_PER_SOL,
                    priorityFee: buyPrio,
                    jitoTip: buyJitoTip * LAMPORTS_PER_SOL,
                };

                const result = await deployToken(deployRequest, walletAddress);
                if (result) {
                    const { mint, signature } = result;
                    toast.success(`Transaction sent: ${signature}.`);
                    console.log('init buy', solInitialBuyThunder);
                    navigate(`Result?walletAddress=${walletAddress}`, {
                        state: {
                            post,
                            background,
                            platform,
                            mint: mint,
                            name: metadata.name,
                            symbol: metadata.symbol,
                            initBuy: solInitialBuyThunder,
                            autoSellDelaySeconds: autoSellDelayThunder,
                            takeProfitPercent: autoSellProfitThunder,
                            imageFile,
                        },
                    });
                }
            } else {
                toast.error('Wallet key is not set');
            }
        } catch (error) {
            toast.error('Error deploying with AI');
            console.error('Error deploying AI:', error);
        }
    }

    return (
        <section>
            <div className={`mes-card ${className}`} style={{ background }}>
                <div className='username-icon'>
                    {isGeneralPost && username && (
                        <div className='username-container'>
                            {icon && <img src={`data:image/jpeg;base64,${icon}`} className='channel-icon' />}
                            <h3 className='username'>{username}</h3>
                        </div>
                    )}
                </div>
                <div className='info'>
                    <h3>{post.text && displayText(post.text, post.fullStoryUrl)}</h3>
                </div>
                <div className='img-section'>
                    {post.media?.length > 0 && imageSrcs.length > 0 ? (
                        imageSrcs.map((src, index) => (
                            <img
                                key={index}
                                className={`post-img ${imageSrcs.length === 1 ? 'single-img' : 'multiple-img'}`}
                                src={src}
                                alt='Post Image'
                                onClick={() => onImageClick && onImageClick(index)}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
                <div className='bottom-section'>
                    <div className='platf-type'>
                        {(platform !== Platform.Telegram || post.username === 'elonalert') && (
                            <div className='tweet-type'>
                                <h3 className={`tweet-type-text ${post.tweetType?.toLowerCase() || 'unknown'}`}></h3>
                            </div>
                        )}
                        <div className='dat-platf'>
                            <div className='platf-btn'>
                                <a
                                    href={post.url ? post.url : '#'}
                                    target='_blank'
                                    onClick={(e) => {
                                        if (!post.url) {
                                            e.preventDefault();
                                            console.log('URL not provided');
                                        }
                                    }}
                                >
                                    <h3>View On {username === 'elonalert' ? 'twitter' : platform}</h3>
                                </a>
                            </div>
                            <h3>{formatTime(new Date(post.date))}</h3>
                        </div>
                    </div>
                    {!isOnTokenCreate && !isOnResultPage && (
                        <section className='buttons'>
                            <div className='instant-ai-btn'>
                                <button
                                    className='instant-btn'
                                    onClick={() => {
                                        handleAiDeployment(post);
                                    }}
                                ></button>
                            </div>
                            <div className={`link-btn ${post.tweetType === 'tweet' ? 'tweet-button' : ''}`}>
                                <button
                                    className='link'
                                    onClick={() => {
                                        navigate(`TokenCreate?walletAddress=${walletAddress}`, { state: { post: post, background, platform } });
                                        console.log('Navigating with post data:', post);
                                    }}
                                >
                                    <span className='span-link'>&#8250;</span>
                                </button>
                            </div>
                        </section>
                    )}
                </div>
            </div>
        </section>
    );
};

export default MessageCard;
