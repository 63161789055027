import { Manager } from 'socket.io-client';

export const createFeedGatewayClient = (url: string, apiKey: string) =>
    new Manager(url, {
        extraHeaders: {
            'x-api-key': apiKey,
        },
    })
        .socket('/feed/gateway')
        .onAnyOutgoing((event: string) => console.debug(`Automatization gateway outgoing`, event));
