import '../Styles/Settings.css';

export function Settings({
    solInitialBuyThunder,
    setSolInitialBuyThunder,
    solInitialBuyArrow,
    setSolInitialBuyArrow,
    solInitialBuyCopy,
    setSolInitialBuyCopy,
    solBuyPrio,
    setSolBuyPrio,
    solSellPrio,
    setSolSellPrio,
    solBuyJito,
    setSolBuyJito,
    solSellJito,
    setSolSellJito,
    autoSellDelayThunder,
    setAutoSellDelayThunder,
    autoSellProfitThunder,
    setAutoSellProfitThunder,
    autoSellDelayArrow,
    setAutoSellDelayArrow,
    autoSellProfitArrow,
    setAutoSellProfitArrow,
    autoSellDelayCreation,
    setAutoSellDelayCopy,
    autoSellProfitCreation,
    setAutoSellProfitCopy,
}: {
    solInitialBuyThunder: number;
    setSolInitialBuyThunder: React.Dispatch<React.SetStateAction<number>>;
    solInitialBuyArrow: number;
    setSolInitialBuyArrow: React.Dispatch<React.SetStateAction<number>>;
    solInitialBuyCopy: number;
    setSolInitialBuyCopy: React.Dispatch<React.SetStateAction<number>>;
    solBuyPrio: number;
    setSolBuyPrio: React.Dispatch<React.SetStateAction<number>>;
    solSellPrio: number;
    setSolSellPrio: React.Dispatch<React.SetStateAction<number>>;
    solBuyJito: number;
    setSolBuyJito: React.Dispatch<React.SetStateAction<number>>;
    solSellJito: number;
    setSolSellJito: React.Dispatch<React.SetStateAction<number>>;
    autoSellDelayThunder: number;
    setAutoSellDelayThunder: React.Dispatch<React.SetStateAction<number>>;
    autoSellProfitThunder: number;
    setAutoSellProfitThunder: React.Dispatch<React.SetStateAction<number>>;
    autoSellDelayArrow: number;
    setAutoSellDelayArrow: React.Dispatch<React.SetStateAction<number>>;
    autoSellProfitArrow: number;
    setAutoSellProfitArrow: React.Dispatch<React.SetStateAction<number>>;
    autoSellDelayCreation: number;
    setAutoSellDelayCopy: React.Dispatch<React.SetStateAction<number>>;
    autoSellProfitCreation: number;
    setAutoSellProfitCopy: React.Dispatch<React.SetStateAction<number>>;
}) {
    return (
        <section className='body-container'>
            <section className='sol-buy-init'>
                <h3>SOL Buy Initial</h3>
                <div className='flash-inp'>
                    <div className='flash-icon'></div>
                    <input
                        className='flash'
                        type='number'
                        value={solInitialBuyThunder}
                        onChange={(event) => {
                            setSolInitialBuyThunder(Number(event.target.value));
                        }}
                    />
                </div>
                <div className='arrow-inp'>
                    <div className='arrow-icon'></div>
                    <input
                        className='arrow'
                        type='number'
                        value={solInitialBuyArrow}
                        onChange={(event) => {
                            setSolInitialBuyArrow(Number(event.target.value));
                        }}
                    />
                </div>
                <div className='copy-inp'>
                    <div className='copy-icon'></div>
                    <input
                        className='copy'
                        type='number'
                        value={solInitialBuyCopy}
                        onChange={(event) => {
                            setSolInitialBuyCopy(Number(event.target.value));
                        }}
                    />
                </div>
            </section>
            <section className='buy-sell-prio'>
                <h3>Buy Prio Fee</h3>
                <input
                    className='buy-sell'
                    type='number'
                    value={solBuyPrio}
                    onChange={(event) => {
                        setSolBuyPrio(Number(event.target.value));
                    }}
                />
                <h3>Sell Prio Fee</h3>
                <input
                    className='buy-sell'
                    type='number'
                    value={solSellPrio}
                    onChange={(event) => {
                        setSolSellPrio(Number(event.target.value));
                    }}
                />
            </section>
            <section className='buy-sell-jito'>
                <h3>Buy Jito Tip</h3>
                <input
                    className='buy-sell'
                    type='number'
                    value={solBuyJito}
                    onChange={(event) => {
                        setSolBuyJito(Number(event.target.value));
                    }}
                />
                <div className='sell-jito'>
                    <h3>Sell Jito Tip</h3>
                    <input
                        className='buy-sell'
                        type='number'
                        value={solSellJito}
                        onChange={(event) => {
                            setSolSellJito(Number(event.target.value));
                        }}
                    />
                </div>
            </section>
            <h3 className='h3-defaults'>Auto Sell Defaults</h3>
            <section className='defaults'>
                <div className='flash-defaults'>
                    <div className='icon-inp'>
                        <div className='flash-icon'></div>
                        <input
                            type='number'
                            className='flash'
                            value={autoSellDelayThunder}
                            onChange={(event) => {
                                setAutoSellDelayThunder(Number(event.target.value));
                            }}
                        />
                        <h3>sec</h3>
                    </div>
                    <div className='inp-text'>
                        <input
                            type='number'
                            className='flash'
                            value={autoSellProfitThunder}
                            onChange={(event) => {
                                setAutoSellProfitThunder(Number(event.target.value));
                            }}
                        />
                        <h3>% profit</h3>
                    </div>
                </div>
                <div className='arrow-defaults'>
                    <div className='icon-inp'>
                        <div className='arrow-icon'></div>
                        <input
                            type='number'
                            className='arrow'
                            value={autoSellDelayArrow}
                            onChange={(event) => {
                                setAutoSellDelayArrow(Number(event.target.value));
                            }}
                        />
                        <h3>sec</h3>
                    </div>
                    <div className='inp-text'>
                        <input
                            type='number'
                            className='arrow'
                            value={autoSellProfitArrow}
                            onChange={(event) => {
                                setAutoSellProfitArrow(Number(event.target.value));
                            }}
                        />
                        <h3>% profit</h3>
                    </div>
                </div>
                <div className='copy-defaults'>
                    <div className='icon-inp'>
                        <div className='copy-icon'></div>
                        <input
                            type='number'
                            className='copy'
                            value={autoSellDelayCreation}
                            onChange={(event) => {
                                setAutoSellDelayCopy(Number(event.target.value));
                            }}
                        />
                        <h3>sec</h3>
                    </div>
                    <div className='inp-text'>
                        <input
                            type='number'
                            className='copy'
                            value={autoSellProfitCreation}
                            onChange={(event) => {
                                setAutoSellProfitCopy(Number(event.target.value));
                            }}
                        />
                        <h3>% profit</h3>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default Settings;
