import '../Styles/TokenCreate.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import MessageCard from '../MessageCard';
import { Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto/post';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TelegramLogin } from '../Telegram/TelegramLogin';
import { useTelegram } from '../Telegram/UseTelegram';
import { RegisterChannel } from '../Telegram/RegisterChannel';
import { getTwitterIdFromUrl } from '../../utils/ge-tweet-id-from-url';
import domtoimage from 'dom-to-image';
import { MediaSource } from '@sodamnfoolish/sjc-backend-types/src/api/feed/media-source';
import { useBackend } from '../../Hooks/UseBackend';
import { ExternalMedia, InternalMedia } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import pumpFunApi from '../../utils/pumpfun/pump-fun-api';
import { DeployRequest } from '@sodamnfoolish/sjc-backend-types/src/api/wallet/dto';
import { useUrlParams } from '../../Hooks/useUrlParams';
import { Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Tweet } from 'react-tweet';
import { IMAGE, URL } from '../../utils/source-constants';
import { base64ToFile } from '../../utils/base64-to-file';
import { connection } from '../../utils/connection';
import { urlToFile } from '../../utils/url-to-file';
import { Pixie } from 'pixie';
import axios from 'axios';
import { DEPLOY_TOKEN_EVENT_NAME } from '../../utils/events';
import { useFeedGeteway } from '../../Hooks/UseFeedGateway';
import bs58 from 'bs58';
import { stickers } from '../../stickers';

let pi: Pixie;
let latestCallback: Function;

export const getPixie = (onSave: (data: string, filename: string, format: string) => void): Pixie => {
    latestCallback = onSave;

    if (pi) return pi;

    pi = new Pixie({
        crossOrigin: true,
        baseUrl: 'https://secretassets.xyz/pixie/assets',
        selector: '#pixie-container',
        tools: {
            stickers: {
                replaceDefault: false,
                items: stickers,
            },
        },
        ui: {
            activeTheme: 'dark',
            allowEditorClose: true,
            openImageDialog: {
                show: false,
            },
        },
        onSave: (data: string, filename: string, format: string) => {
            latestCallback(data, filename, format);
        },
    });

    return pi;
};

export function TokenCreate({
    solInitialBuyArrow,
    setSolInitialBuyArrow,
    buyJitoTip,
    buyPrio,
    autoSellDelayArrow,
    autoSellProfitArrow,
    autoSellDelayCreation,
    autoSellProfitCreation,
}: {
    solInitialBuyArrow: number;
    setSolInitialBuyArrow: React.Dispatch<React.SetStateAction<number>>;
    buyJitoTip: number;
    buyPrio: number;
    autoSellDelayArrow: number;
    autoSellProfitArrow: number;
    autoSellDelayCreation: number;
    autoSellProfitCreation: number;
}) {
    const navigate = useNavigate();
    const location = useLocation();
    const { post, background, platform }: { post: Post; background: string; platform: string } = location.state || {};
    const { getMedia, getChatGptMetadataFromPostId, generateTokenMetadataByTweetUrl, generateTokenMetadataByUrl, getTokenMetadata, deployToken } = useBackend();
    const { walletAddress } = useUrlParams();
    const { authorized } = useTelegram();
    const [telegramChannelUsername, setTelegramChannelUsername] = useState<string>('');
    const [autofillValue, setAutofillValue] = useState('');
    const [commentsAboveCode, setCommentsAboveCode] = useState<string>('https://t.me/ThisIsName');

    const [walletBalance, setWalletBalance] = useState<number>(0);

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [imageFromUrl, setImageFromUrl] = useState<string>('');

    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [description, setDescription] = useState<string>(post?.text || '');
    const [telegram, setTelegram] = useState<string>('');
    const [twitter, setTwitter] = useState<string>('');
    const [website, setWebsite] = useState<string>('');

    const [symbolAutocapChecked, setSymbolAutocapChecked] = useState(true);
    const [solanaTokenToParse, setSolanaTokenToParse] = useState<string>('');
    const [tweetUrlToParse, setTweetUrlToParse] = useState<string>('');
    const [webUrlToParse, setWebUrlToParse] = useState<string>('');

    const { feedGatewayClient } = useFeedGeteway();
    const { sessionId } = useUrlParams();

    const [splAutoDeployChecked, setSplAutoDeployChecked] = useState(false);
    const [pumpAutoDeployChecked, setPumpAutoDeployChecked] = useState(false);
    const [ercAutoDeployChecked, setErcAutoDeployChecked] = useState(false);

    enum RadioOptions {
        PumpSuffix = 'Pump Suffix',
        CustomAddress = 'Custom Wallet',
    }

    const [radioSelectedOption, setRadioSelectedOption] = useState<RadioOptions>(RadioOptions.PumpSuffix);
    const [customTokenPrivateKey, setCustomTokenPrivateKey] = useState<string>('');

    useEffect(() => {
        let subId: number;
        if (walletAddress) {
            connection.getBalance(new PublicKey(walletAddress)).then((balance) => {
                setWalletBalance(balance / LAMPORTS_PER_SOL);
            });
            console.log('Subscribing to account changes for wallet:', walletAddress);
            subId = connection.onAccountChange(new PublicKey(walletAddress), (account) => {
                setWalletBalance(account.lamports / LAMPORTS_PER_SOL);
            });
        }

        return () => {
            if (subId) connection.removeAccountChangeListener(subId);
        };
    }, [walletAddress]);

    const preventSymbols = (event: { key: string; preventDefault: () => void }) => {
        const validCharacters = /^[a-zA-Z0-9_]+$/;

        if (!validCharacters.test(event.key)) {
            event.preventDefault();
        }
    };

    const removeEmojis = (text: string) => {
        return text.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF\u0023-\u0039]\uFE0F?|[\u200D\u20E3\u3297\u3299]\uFE0F?|[\u00A9\u00AE\u303D\u3030\u2B50\u2B55\u231A\u231B\u25AA\u25AB\u25B6\u25C0\u25FB\u25FC\u25FD\u25FE\u00A9\u00AE\u303D\u3030\u2B50\u2B55\u231A\u231B\u25AA\u25AB\u25B6\u25C0\u25FB\u25FC\u25FD\u25FE])+|\uD83D[\uDC00-\uDFFF]/g,
            '',
        );
    };

    const getFirstWord = (text: string, caps: boolean = false): string => {
        if (!text) return '';

        const removeUnwantedSegments = (input: string): string => {
            let result = input.replace(/::\[Reply\]/gi, '');
            result = result.replace(/@\w+/g, '');
            result = result.replace(/\d{2}:\d{2}:\d{2}/g, '');
            result = result.replace(/\[.*?\]/g, '');
            result = result.replace(/[^\w\s]|_/g, '');
            return result.trim();
        };

        const cleanText = removeEmojis(removeUnwantedSegments(text));

        let words = cleanText.split(/\s+/).filter((word) => word.length > 0);

        words = words.filter((word) => word.length > 1);

        let firstWord = '';

        if (cleanText.toLowerCase().startsWith('just in')) {
            const wordsAfterKeyword = words.slice(2);
            firstWord = wordsAfterKeyword[0] || '';
        } else if (cleanText.toLowerCase().startsWith('breaking')) {
            const wordsAfterKeyword = words.slice(1);
            firstWord = wordsAfterKeyword[0] || '';
        } else if (cleanText.toLowerCase().startsWith('rt')) {
            const wordsAfterKeyword = words.slice(1);
            firstWord = wordsAfterKeyword[0] || '';
        } else {
            firstWord = words[0] || '';
        }

        return caps ? firstWord.toUpperCase() : firstWord;
    };

    const capitalizeFirstLetter = (text: string) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const removeDoubleStar = (text: string) => {
        return text.replaceAll('*', '');
    };

    useEffect(() => {
        if (post && post.text) {
            setSymbol(getFirstWord(post.text, true));
            setName(capitalizeFirstLetter(getFirstWord(post.text, false)));
            setDescription(removeDoubleStar(post.text));
        }
    }, [post?.text]);

    const blobToFile = (blob: Blob, filename: string): File => {
        return new File([blob], filename, { type: blob.type });
    };
    const blobToBase64 = (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = () => reject('Error converting blob to Base64');
            reader.readAsDataURL(blob);
        });
    };

    const fetchFirstImage = async () => {
        if (post && post.media && post.media.length > 0) {
            const mediaItem = post.media[0];
            try {
                if (mediaItem.source === MediaSource.Internal) {
                    const blob = await getMedia((mediaItem as InternalMedia).id);
                    const base64Image = await blobToBase64(blob);
                    setImageSrc(base64Image);
                    setImageFile(blobToFile(blob, 'postImage.jpg'));
                } else if (mediaItem.source === MediaSource.External) {
                    const response = await fetch((mediaItem as ExternalMedia).url);
                    const blob = await response.blob();
                    const base64Image = await blobToBase64(blob);
                    setImageSrc(base64Image);
                    setImageFile(blobToFile(blob, 'postImage.jpg'));
                }
            } catch (error) {
                console.error('Error fetching media:', error);
            }
        } else if (image) {
            const base64Image = `data:image/jpeg;base64,${image}`;
            setImageSrc(base64Image);
            const file = base64ToFile(base64Image, 'sourceImage.jpg');
            setImageFile(file);
        }
    };

    const imageClick = async (index: number) => {
        if (post && post.media && post.media.length > 0) {
            const mediaItem = post.media[index];

            try {
                if (mediaItem.source === MediaSource.Internal) {
                    const blob = await getMedia((mediaItem as InternalMedia).id);
                    const base64Image = await blobToBase64(blob);
                    setImageSrc(base64Image);
                    setImageFile(blobToFile(blob, 'postImage.jpg'));
                } else if (mediaItem.source === MediaSource.External) {
                    const externalUrl = (mediaItem as ExternalMedia).url;
                    const response = await fetch(externalUrl);
                    const blob = await response.blob();
                    const base64Image = await blobToBase64(blob);
                    setImageSrc(base64Image);
                    setImageFile(blobToFile(blob, 'postImage.jpg'));
                }
            } catch (error) {
                console.error('Error fetching media:', error);
            }
        }
    };

    const imageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            blobToBase64(file)
                .then((base64Image) => {
                    setImageSrc(base64Image);
                    setImageFile(file);
                })
                .catch((error) => {
                    console.error('Error converting file to Base64:', error);
                });
        }
    };

    useEffect(() => {
        fetchFirstImage();
    }, []);

    const tweetRef = useRef<HTMLDivElement>(null);

    const setScreenshot = async () => {
        if (tweetRef.current) {
            domtoimage
                .toPng(tweetRef.current, { quality: 100 })
                .then((dataUrl: string) => {
                    setImageSrc(dataUrl);
                    const file = base64ToFile(dataUrl, 'postImage.jpg');
                    setImageFile(file);
                })
                .catch((error) => {
                    console.error('Screenshot failed!', error);
                });
        }
    };

    const downloadScreenshot = async () => {
        if (tweetRef.current) {
            domtoimage
                .toPng(tweetRef.current, { quality: 100 })
                .then((dataUrl: string) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'tweet-screenshot.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Screenshot failed!', error);
                });
        }
    };

    const getAIMetadata = async (post: Post) => {
        const metadata = await getChatGptMetadataFromPostId(post.id);
        setName(metadata.name);
        setSymbol(metadata.symbol);
        setDescription(post.url);
    };

    const deployTokenFromMetadata = async (name: string, symbol: string, imageFile: File | null) => {
        if (!imageFile) return toast.error('no image file');
        if (!walletAddress) return toast.error('no wallet address');
        toast.info('Uploading metadata...');
        const uploadMetadataResponse = await pumpFunApi.uploadMetadata({
            file: imageFile,
            name: name,
            symbol: symbol,
            description: description,
            twitter: twitter,
            telegram: telegram,
            website: website,
            showName: 'true',
        });
        let mintSecretKeyBase58: string | undefined = undefined;
        if (radioSelectedOption === RadioOptions.CustomAddress) {
            try {
                Keypair.fromSecretKey(bs58.decode(customTokenPrivateKey));
                mintSecretKeyBase58 = customTokenPrivateKey;
            } catch {
                toast.error('Invalid private key');
                return;
            }
        }

        const deployRequest: DeployRequest = {
            name: name,
            symbol: symbol,
            metadataUri: uploadMetadataResponse.metadataUri,
            solAmount: solInitialBuyArrow * LAMPORTS_PER_SOL,
            priorityFee: buyPrio,
            jitoTip: buyJitoTip * LAMPORTS_PER_SOL,
            mintSecretKeyBase58: mintSecretKeyBase58,
        };

        const result = await deployToken(deployRequest, walletAddress);

        if (result) {
            const { mint, signature } = result;
            toast.success(`Transaction sent: ${signature}.`);
            navigate(`/Result?walletAddress=${walletAddress}`, {
                state: {
                    post,
                    background,
                    platform,
                    mint: mint,
                    name: name,
                    symbol: symbol,
                    initBuy: solInitialBuyArrow,
                    autoSellDelaySeconds: post ? autoSellDelayArrow : autoSellDelayCreation,
                    takeProfitPercent: post ? autoSellProfitArrow : autoSellProfitCreation,
                    imageFile,
                },
            });
        }
    };

    function getSourceDetails() {
        const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
            return base[platform] && (base[platform][post.username as keyof T] as string);
        };

        const image = getDetails(IMAGE, platform);
        const url = getDetails(URL, platform);

        return { image, url };
    }

    const { image, url } = getSourceDetails();

    const extractTextFromImage = async (imageFile: File | Blob) => {
        const formData = new FormData();
        formData.append('file', imageFile);
        formData.append('apikey', process.env.REACT_APP_OCR_API_KEY || '');

        try {
            const response = await fetch('https://api.ocr.space/parse/image', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();

            if (result.IsErroredOnProcessing) {
                console.error('Error in OCR processing:', result.ErrorMessage);
                return '';
            }

            return result.ParsedResults[0]?.ParsedText || '';
        } catch (error) {
            console.error('Error while calling OCR API:', error);
            return '';
        }
    };

    const handleExtractText = async () => {
        if (!imageFile) {
            toast.error('Upload image first');
            return;
        }

        const extractedText = await extractTextFromImage(imageFile);
        if (extractedText) {
            setDescription((prevDescription) => `${extractedText}\n${prevDescription}`);
            toast.success('Text extract successfully!');
        } else {
            toast.error('Image dont have text');
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => setIsOpen(true);
    const closePopup = () => setIsOpen(false);

    const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget) {
            closePopup();
        }
    };

    const formatWalletAddress = (address: string | undefined) => {
        if (!address) return '';
        if (address.length <= 10) return address;
        const start = address.slice(0, 5);
        const end = address.slice(-5);
        return `${start}...${end}`;
    };

    function copyToClipboard(walletAddress: string) {
        navigator.clipboard
            .writeText(walletAddress)
            .then(() => {
                toast.success('Copy to clipboard');
            })
            .catch((error) => {
                toast.error('Error copying to clipboard');
                console.error('Error copying to clipboard:', error);
            });
    }

    async function parseTokenMetadata(mint: string) {
        const metadata = await getTokenMetadata(mint);

        if (!metadata) {
            toast.error('No metadata found');
            return;
        }
        setName(metadata.name || '');
        setSymbol(metadata.symbol || '');
        getImageFromUrl(metadata.image || '');
        setDescription(metadata.description || '');
        setTelegram(metadata.telegram || '');
        setTwitter(metadata.twitter || '');
        setWebsite(metadata.website || '');
    }

    async function getImageFromUrl(url: string) {
        setImageSrc(url);
        const file = await urlToFile(url, 'metadataImage.jpg', 'image/jpeg');
        setImageFile(file);
        closePopup();
    }

    const pixieInstance = getPixie((data: string, filename: string, format: string) => {
        console.log(data);

        setImageSrc(data);
        setImageFile(base64ToFile(data, `${filename}.${format}`));

        const pixieContainer = document.getElementById('pixie-container');
        pixieContainer!.style.opacity = '0';
        pixieContainer!.style.pointerEvents = 'none';
    });

    useEffect(() => {
        pixieInstance?.tools?.import?.openBackgroundImage(imageSrc);
    }, [pixieInstance, imageSrc]);

    const crop = (cropFn: (box: Pick<InteractableRect, 'height' | 'width'>) => InteractableRect) => {
        if (imageSrc) {
            var image = new Image();
            image.src = imageSrc;

            image.onload = function () {
                let { width, height } = this as any;

                pixieInstance?.tools.crop.apply(cropFn({ width, height }));

                setTimeout(() => {
                    let image = pixieInstance?.tools.export.getDataUrl();

                    console.log(image);

                    if (image) {
                        setImageSrc(image);
                        setImageFile(base64ToFile(image, 'profilePic.jpg'));
                    }
                }, 200);
            };
        }
    };

    async function generateMetadataByTweetUrl(url: string) {
        if (!url) {
            return toast.error('URL not provided');
        }
        toast.info('Generating metadata from tweet URL...');
        const metadata = await generateTokenMetadataByTweetUrl(url);
        setName(metadata.name || '');
        setSymbol(metadata.symbol || '');
        setTelegram('');
        setWebsite('');
        setDescription(url || '');
        setTwitter(url || '');

        toast.success('Metadata generated successfully!');
    }

    async function generateMetadataByUrl(url: string) {
        if (!url) {
            return toast.error('URL not provided');
        }
        toast.info('Generating metadata from web URL...');
        const metadata = await generateTokenMetadataByUrl(url);
        setName(metadata.name || '');
        setSymbol(metadata.symbol || '');
        setDescription(url || '');
        setTelegram('');
        setWebsite(url || '');
        setTwitter('');

        toast.success('Metadata generated successfully!');
    }

    interface InteractableRect {
        left: number;
        top: number;
        width: number;
        height: number;
        angle?: number;
    }

    function cropCenter({ width, height }: Pick<InteractableRect, 'height' | 'width'>): InteractableRect {
        let diff = Math.abs(width - height);

        if (height > width) {
            return { left: 0, top: diff / 2, width, height: height - diff };
        } else {
            return { left: diff / 2, top: 0, width: width - diff, height };
        }
    }

    async function uploadImageToIpfs(imageFile: File) {
        try {
            toast.info('Uploading image to IPFS...', {
                autoClose: 2500,
                closeOnClick: true,
                draggable: true,
            });
            const formData = new FormData();
            formData.append('file', imageFile);

            const metadata = JSON.stringify({ name: 'File name' });
            formData.append('pinataMetadata', metadata);

            const options = JSON.stringify({ cidVersion: 0 });
            formData.append('pinataOptions', options);

            const responseData = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
                maxBodyLength: Infinity,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
                },
            });

            const fileUrl = 'https://gateway.pinata.cloud/ipfs/' + responseData.data.IpfsHash;

            toast.success('Image uploaded successfully!', {
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
                draggable: true,
            });

            return fileUrl;
        } catch (error) {
            toast.error('Failed to upload image to IPFS!', {
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
                draggable: true,
            });

            throw new Error('Error uploading image to IPFS: ' + error);
        }
    }

    async function sendTokenToRemoteApp(appName: string, autoDeploy: boolean) {
        try {
            let url: string = '';
            if (imageFile) {
                url = await uploadImageToIpfs(imageFile);
            }
            console.log('url', url);

            const metadata = {
                tokenName: name,
                tokenSymbol: symbol,
                tokenDescription: description,
                customImageUrl: url,
                autoDeploy: autoDeploy,
            };

            feedGatewayClient!.emit(DEPLOY_TOKEN_EVENT_NAME, appName, sessionId, metadata);

            toast.success(`Deploy ${appName} event sent!`);
        } catch (error) {
            toast.error(`Failed to send deploy event`);
            console.error('Failed to send deploy event:', error);
        }
    }

    return (
        <>
            <section className='card-settings-container'>
                <section className='card'>
                    <div className='mes-card-form'>
                        {post ? (
                            <>
                                <div className='header-card'>
                                    <div className='icon-naming-link'>
                                        <img className='icon' src={`data:image/jpeg;base64,${image}`} />
                                        <div className='name-url'>
                                            {post ? (
                                                <>
                                                    <h3>{post.username}</h3>
                                                    <a
                                                        className='link-to-channel'
                                                        href={url ? url : '#'}
                                                        target='_blank'
                                                        onClick={(e) => {
                                                            if (!url) {
                                                                e.preventDefault();
                                                                console.log('URL not provided');
                                                            }
                                                        }}
                                                    >
                                                        <h3>{url && url.includes('https://') ? url.slice(8) : url}</h3>
                                                    </a>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <MessageCard
                                    post={post}
                                    background={background}
                                    onImageClick={imageClick}
                                    platform={platform}
                                    username={post.username}
                                    solInitialBuyThunder={0} //not used on create page
                                    autoSellDelayThunder={0}
                                    autoSellProfitThunder={0}
                                    buyJitoTip={0}
                                    buyPrio={0}
                                />
                            </>
                        ) : (
                            <div className='no-post-side-panel'>
                                <div className='side-panel-case'>
                                    <h3>Parse Existing Solana Token</h3>
                                    <div className='side-panel-inp-btn-case'>
                                        <input
                                            type='text'
                                            value={solanaTokenToParse}
                                            onChange={(e) => {
                                                setSolanaTokenToParse(e.target.value);
                                            }}
                                        />
                                        <button
                                            className='pic-btn'
                                            onClick={() => {
                                                parseTokenMetadata(solanaTokenToParse);
                                            }}
                                        >
                                            Parse
                                        </button>
                                    </div>
                                </div>
                                <div className='side-panel-case'>
                                    <h3>Ask AI To Generate From Tweet URL</h3>
                                    <div className='side-panel-inp-btn-case'>
                                        <input
                                            type='text'
                                            value={tweetUrlToParse}
                                            onChange={(e) => {
                                                setTweetUrlToParse(e.target.value);
                                            }}
                                        />
                                        <button
                                            className='pic-btn'
                                            onClick={() => {
                                                generateMetadataByTweetUrl(tweetUrlToParse);
                                            }}
                                        >
                                            Start
                                        </button>
                                    </div>
                                </div>
                                <div className='side-panel-case'>
                                    <h3>Ask AI To Generate From Web URL</h3>
                                    <div className='side-panel-inp-btn-case'>
                                        <input
                                            type='text'
                                            value={webUrlToParse}
                                            onChange={(e) => {
                                                setWebUrlToParse(e.target.value);
                                            }}
                                        />
                                        <button
                                            className='pic-btn'
                                            onClick={() => {
                                                generateMetadataByUrl(webUrlToParse);
                                            }}
                                        >
                                            Start
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='tweetForm'>
                        {post && (post.url.includes('twitter.com') || post.url.includes('x.com')) ? (
                            <div data-theme='dark' style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
                                <div
                                    className='display-tweet-form'
                                    data-theme='dark'
                                    style={{
                                        position: 'relative',
                                        width: '100%',

                                        overflow: 'hidden',
                                        zIndex: 2,
                                    }}
                                >
                                    <TwitterTweetEmbed tweetId={getTwitterIdFromUrl(post.url)} options={{ theme: 'dark' }} />
                                </div>
                                <div
                                    className='screen-tweet-form'
                                    ref={tweetRef}
                                    style={{
                                        position: 'absolute',
                                        overflow: 'hidden',
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    <div className='form1'>
                                        <Tweet id={getTwitterIdFromUrl(post.url)} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p></p>
                        )}
                    </div>
                    <div className='ocr-case'>
                        {post && (post.url.includes('twitter.com') || post.url.includes('x.com')) ? (
                            <div className='ocr-text'>
                                <div>
                                    <h3 className='ocr-headline'>OCR:</h3>
                                </div>
                                <div>
                                    <h3 className='ocr-main-text'>Example 1234556</h3>
                                </div>
                            </div>
                        ) : (
                            <p></p>
                        )}
                    </div>
                </section>
                <section className='create-token-container'>
                    <div className='sett-naming-container'>
                        <div className='naming'>
                            <h3>Name</h3>
                            <input
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                                type='text'
                            />
                        </div>
                        <div className='symbols'>
                            <h3>Symbol</h3>
                            <input
                                type='text'
                                className='symbol-input'
                                value={symbol}
                                onChange={(event) => {
                                    let text = event.target.value;
                                    text = symbolAutocapChecked ? text.toUpperCase() : text;

                                    function trimTextToFitLimits(text: string) {
                                        const specialCharRegex = /[^a-zA-Z0-9]/g;
                                        const regularCharRegex = /[a-zA-Z0-9]/g;

                                        const specialChars = text.match(specialCharRegex) || [];
                                        const regularChars = text.match(regularCharRegex) || [];

                                        let result = '';

                                        if (specialChars.length > 2 || (specialChars.length === 1 && regularChars.length > 6) || regularChars.length > 10) {
                                            if (specialChars.length > 2) {
                                                result += specialChars.slice(0, 2).join('');
                                            } else if (specialChars.length === 1 && regularChars.length > 6) {
                                                result += specialChars.join('') + regularChars.slice(0, 6).join('');
                                            } else if (regularChars.length > 10) {
                                                result += regularChars.slice(0, 10).join('');
                                            }
                                        } else {
                                            result = text;
                                        }

                                        return result;
                                    }

                                    text = trimTextToFitLimits(text);
                                    setSymbol(text);
                                }}
                            />
                            <input
                                type='checkbox'
                                className='symbol-checkbox'
                                checked={symbolAutocapChecked}
                                onChange={() => {
                                    if (!symbolAutocapChecked) {
                                        setSymbol(symbol.toUpperCase());
                                    }
                                    setSymbolAutocapChecked(!symbolAutocapChecked);
                                }}
                            />
                            <h3>AutoCap</h3>
                        </div>
                        <div className='description-case'>
                            <h3>Description</h3>
                            <textarea
                                value={description}
                                onChange={(event) => {
                                    setDescription(event.target.value);
                                }}
                            />
                        </div>
                        <div className='generate-extract-btn-case'>
                            {post ? (
                                <div className='generate-ai-btn'>
                                    <button
                                        className='ai-btn'
                                        onClick={() => {
                                            getAIMetadata(post);
                                        }}
                                    >
                                        Generate with AI
                                    </button>
                                </div>
                            ) : null}
                            {post ? (
                                <div className='extract-text-btn'>
                                    <button className='ai-btn' onClick={handleExtractText}>
                                        Text extract image
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='sett-container'>
                        <div className='img-case'>
                            <div className='img-btn'>
                                <div className='img'>{imageSrc ? <img src={imageSrc} alt='Uploaded' /> : 'image from post'}</div>
                                <div className='btn-case'>
                                    <div className='image-manipulation-btns'>
                                        <button
                                            data-tooltip='Remove Image'
                                            className='delete-btn'
                                            onClick={() => {
                                                setImageSrc(null);
                                                setImageFile(null);
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = '';
                                                }
                                            }}
                                        ></button>
                                        <button
                                            className='image-editor-btn'
                                            data-tooltip='Open Image Editor'
                                            onClick={() => {
                                                if (imageSrc) {
                                                    pixieInstance?.open({ image: imageSrc });
                                                    const pixieContainer = document.getElementById('pixie-container');
                                                    pixieContainer!.style.opacity = '1';
                                                    pixieContainer!.style.pointerEvents = 'all';
                                                }
                                            }}
                                        ></button>
                                    </div>
                                    <div className='image-manipulation-btns'>
                                        <label className='upload-icon' data-tooltip='Upload Image'>
                                            <input type='file' accept='image/*' onChange={imageFileChange} ref={fileInputRef} />
                                        </label>
                                        <button className='import-menu-btn' data-tooltip='Import Image From URL' onClick={openPopup}></button>
                                        {isOpen && (
                                            <div className='popup-container' onClick={handleOutsideClick}>
                                                <div className='popup'>
                                                    <div className='popup-header'>
                                                        <h3>Import Image From Web</h3>
                                                        <button onClick={closePopup} className='close-btn'>
                                                            &times;
                                                        </button>
                                                    </div>
                                                    <div className='import-inp-btn'>
                                                        <input
                                                            type='text'
                                                            placeholder='Enter image URL'
                                                            className='popup-input'
                                                            onChange={(e) => {
                                                                setImageFromUrl(e.target.value);
                                                            }}
                                                        />
                                                        <button
                                                            className='import-btn'
                                                            onClick={() => {
                                                                getImageFromUrl(imageFromUrl);
                                                            }}
                                                        >
                                                            Import
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {post && (platform === 'twitter' || post.username === 'elonalert') && (
                                        <div>
                                            <div className='set-btn-case'>
                                                <button className='set-btn' onClick={setScreenshot} data-tooltip='Capture & Set Screenshot'></button>
                                            </div>
                                            <div className='download-btn-case'>
                                                <button
                                                    className='download-btn'
                                                    onClick={downloadScreenshot}
                                                    data-tooltip='Capture & Download Screenshot'
                                                ></button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='picture-buttons-case'>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                crop(({ height, width }) => {
                                    let diff = height - width;

                                    if (diff > 0) {
                                        return { left: 0, top: 0, width, height: height - diff };
                                    } else {
                                        return cropCenter({ height, width });
                                    }
                                });
                            }}
                        >
                            Crop Upper
                        </button>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                crop(cropCenter);
                            }}
                        >
                            Crop Center
                        </button>
                        <button
                            className='pic-btn'
                            onClick={() => {
                                if (image) {
                                    setImageSrc(`data:image/jpeg;base64,${image}`);
                                    const file = base64ToFile(`data:image/jpeg;base64,${image}`, 'profilePic.jpg');
                                    setImageFile(file);
                                } else {
                                    console.error('No image available in source details');
                                }
                            }}
                        >
                            Profile Pic
                        </button>
                    </div>
                    <div className='links-container'>
                        <div className='link-case'>
                            <h3>Telegram</h3>
                            <input
                                type='text'
                                className='links'
                                value={telegram}
                                onChange={(e) => {
                                    setTelegram(e.target.value);
                                }}
                            />
                        </div>
                        <div className='link-case'>
                            <h3>Twitter</h3>
                            <input
                                type='text'
                                className='links'
                                value={twitter}
                                onChange={(e) => {
                                    setTwitter(e.target.value);
                                }}
                            />
                        </div>
                        <div className='link-case'>
                            <h3>Website</h3>
                            <input
                                type='text'
                                className='links'
                                value={website}
                                onChange={(e) => {
                                    setWebsite(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </section>
                <section className='tg-channel'>
                    <div className='send-instant-case'>
                        <div className='instant-pump-case'>
                            <div className='wallet-balance-container'>
                                <h3>Wallet:</h3>
                                <p>{formatWalletAddress(walletAddress)}</p>
                                <button className='copy-btn' onClick={() => copyToClipboard(walletAddress)}></button>
                                <h3>Balance:</h3>
                                <p>{walletBalance.toFixed(3)}</p>
                            </div>
                            <div className='radio-custom-pump'>
                                <label className='radio-option'>
                                    <input
                                        type='radio'
                                        className='option'
                                        value={RadioOptions.PumpSuffix}
                                        checked={radioSelectedOption === RadioOptions.PumpSuffix}
                                        onChange={(e) => setRadioSelectedOption(e.target.value as RadioOptions)}
                                    />
                                    <div>
                                        <p>Pump</p>
                                        <p>Suffix</p>
                                    </div>
                                </label>
                                <label className='radio-option'>
                                    <input
                                        type='radio'
                                        className='option'
                                        value={RadioOptions.CustomAddress}
                                        checked={radioSelectedOption === RadioOptions.CustomAddress}
                                        onChange={(e) => setRadioSelectedOption(e.target.value as RadioOptions)}
                                    />
                                    <div>
                                        <p>Custom</p>
                                        <p>Wallet</p>
                                    </div>
                                </label>

                                <input
                                    type='text'
                                    className='custom-wallet-address'
                                    placeholder='Enter custom private key'
                                    value={customTokenPrivateKey}
                                    onChange={(e) => setCustomTokenPrivateKey(e.target.value)}
                                    disabled={radioSelectedOption !== RadioOptions.CustomAddress}
                                />
                            </div>
                            <div className='instant-case'>
                                <div className='instant-pump'>
                                    <button
                                        className='instant-pump'
                                        onClick={() => {
                                            deployTokenFromMetadata(name, symbol, imageFile);
                                        }}
                                    >
                                        Instant PUMP
                                    </button>
                                </div>
                                <div className='sol-initial-buy-case'>
                                    <h3>SOL Initial Buy</h3>
                                    <input
                                        type='number'
                                        value={solInitialBuyArrow}
                                        onChange={(event) => {
                                            const value = parseFloat(event.target.value);
                                            setSolInitialBuyArrow(value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='send-case'>
                            <div className='send-spl'>
                                <button className='spl' onClick={() => sendTokenToRemoteApp('SPL', splAutoDeployChecked)}>
                                    Send to SPL{' '}
                                </button>
                                <div className='deploy-session'>
                                    <div className='session'>
                                        <p>Session ID: {sessionId}</p>
                                    </div>
                                    <div className='auto-deploy'>
                                        <input type='checkbox' checked={splAutoDeployChecked} onClick={() => setSplAutoDeployChecked((prev) => !prev)} />

                                        <p>Auto Deploy</p>
                                    </div>
                                </div>
                            </div>
                            <div className='send-pump'>
                                <button className='pump' onClick={() => sendTokenToRemoteApp('PUMP', pumpAutoDeployChecked)}>
                                    Send to PUMP{' '}
                                </button>
                                <div className='deploy-session'>
                                    <div className='session'>
                                        <p>Session ID: {sessionId}</p>
                                    </div>
                                    <div className='auto-deploy'>
                                        <input type='checkbox' checked={pumpAutoDeployChecked} onClick={() => setPumpAutoDeployChecked((prev) => !prev)} />
                                        <p>Auto Deploy</p>
                                    </div>
                                </div>
                            </div>
                            <div className='send-erc'>
                                <button className='erc' onClick={() => sendTokenToRemoteApp('ERC', ercAutoDeployChecked)}>
                                    Send to ERC{' '}
                                </button>
                                <div className='deploy-session'>
                                    <div className='session'>
                                        <p>Session ID: {sessionId}</p>
                                    </div>
                                    <div className='auto-deploy'>
                                        <input type='checkbox' checked={ercAutoDeployChecked} onClick={() => setErcAutoDeployChecked((prev) => !prev)} />
                                        <p>Auto Deploy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tg-create-container'>
                        <h3>Telegram Channel Creation</h3>
                        <div>{authorized === true ? null : <TelegramLogin />}</div>
                        <div className='tg'>
                            {authorized === true ? (
                                <RegisterChannel
                                    telegramChannelUsername={telegramChannelUsername}
                                    setTelegramChannelUsername={setTelegramChannelUsername}
                                    autofillValue={autofillValue}
                                    setCommentsAboveCode={setCommentsAboveCode}
                                    commentsAboveCode={commentsAboveCode}
                                    preventSymbols={preventSymbols}
                                />
                            ) : null}{' '}
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}
