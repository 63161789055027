import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { createFeedGatewayClient } from '../utils/backend/session';

export const useFeedGeteway = () => {
    const [feedGatewayClient, setFeedGatewayClient] = useState<Socket>();

    useEffect(() => {
        const feedSocket = createFeedGatewayClient(process.env.REACT_APP_BACKEND_URL!, process.env.REACT_APP_BACKEND_APIKEY!);

        feedSocket.on('connect', () => console.debug('Automatization client connected', feedSocket.id));
        feedSocket.on('connect_error', () => console.debug('Automatization client connection error'));
        feedSocket.on('disconnect', () => console.debug('Automatization client disconnected'));

        feedSocket.connect();

        setFeedGatewayClient(feedSocket);

        return () => {
            feedSocket.off('connect');
            feedSocket.off('connect_error');
            feedSocket.off('disconnect');
            feedSocket.disconnect();
        };
    }, []);

    return {
        feedGatewayClient,
    };
};
