import { useState, useEffect } from 'react';
import { useBackend } from '../Hooks/UseBackend';
import { Feed } from '../post-interface';
import MessageCard from './MessageCard';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import './Styles/CommonFeed.css';
import { COLOR, IMAGE, URL } from '../utils/source-constants';

export function CommonFeed({
    sources,
    feed,
    setCommonFeed,
    highlightedPosts,
    solInitialBuyThunder,
    autoSellDelayThunder,
    autoSellProfitThunder,
    buyPrio,
    buyJito,
    username,
}: {
    sources: Api.Feed.DTO.Source[];
    feed: Feed;
    setCommonFeed: React.Dispatch<React.SetStateAction<Feed>>;
    highlightedPosts: Set<string>;
    solInitialBuyThunder: number;
    autoSellDelayThunder: number;
    autoSellProfitThunder: number;
    buyPrio: number;
    buyJito: number;
    username: string;
}) {
    const { getFeed } = useBackend();

    useEffect(() => {
        console.log('sources:', sources);
        if (sources.length > 0) {
            const fetchFeeds = async () => {
                try {
                    const posts = await getFeed([...sources]);
                    const newFeed: Feed = { Everyone: posts };

                    setCommonFeed(newFeed);
                } catch (err) {
                    console.error('Error fetching general posts:', err);
                }
            };

            fetchFeeds();
        }
    }, [sources]);

    return (
        <section>
            <div className='header-title'></div>
            <div className='container-form'>
                <div className='feed-source'>
                    <div className='name'>
                        <h3>{username}</h3>
                    </div>
                </div>
                <div className='form'>
                    {feed.Everyone && feed.Everyone.length > 0 ? (
                        feed.Everyone.map((post) => {
                            const { platform, username } = post;

                            function getSourceDetails() {
                                const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
                                    return base[platform] && (base[platform][username as keyof T] as string);
                                };

                                const color = getDetails(COLOR, platform);
                                const image = getDetails(IMAGE, platform);
                                const url = getDetails(URL, platform);

                                return { color, image, url };
                            }

                            const { color, image } = getSourceDetails();

                            const gradients = getComputedStyle(document.body);
                            const currentGradient = gradients.getPropertyValue(color);
                            return (
                                <MessageCard
                                    post={post}
                                    key={post.id}
                                    background={currentGradient}
                                    platform={platform}
                                    username={username}
                                    isGeneralPost={true}
                                    icon={image}
                                    className={highlightedPosts.has(post.id) ? 'highlight' : ''}
                                    solInitialBuyThunder={solInitialBuyThunder}
                                    autoSellDelayThunder={autoSellDelayThunder}
                                    autoSellProfitThunder={autoSellProfitThunder}
                                    buyPrio={buyPrio}
                                    buyJitoTip={buyJito}
                                />
                            );
                        })
                    ) : (
                        <div className='load'>Loading...</div>
                    )}
                </div>
            </div>
        </section>
    );
}
