import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useUrlParams() {
    const [walletAddress, setWalletAddress] = useState<string>('');
    const [sessionId, setSessionId] = useState<string>('0');
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const sessionIdParam = urlParams.get('sessionId');
        const walletAddress = urlParams.get('walletAddress');

        setWalletAddress(walletAddress || '');
        setSessionId(sessionIdParam || '0');
    }, [location.search]);

    return { walletAddress, sessionId };
}
