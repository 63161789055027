import { Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';

export enum Platform {
    Twitter = 'twitter',
    Telegram = 'telegram',
}

export interface Feed {
    [source: string]: Post[];
}

export interface SoundStatus {
    [source: string]: boolean;
}
