import { useState, useEffect } from 'react';

function InteractionBanner() {
    const [hasInteracted, setHasInteracted] = useState(false);

    const handleUserInteraction = () => {
        setHasInteracted(true);
    };

    useEffect(() => {
        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
        };
    }, []);

    if (!hasInteracted) {
        return (
            <div
                style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '10px',
                    textAlign: 'center',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 1000,
                    marginTop: '60px',
                }}
            >
                You Must Click Here To Enable Sounds/Notifications - Chrome System Requirement For Every New Website or Refresh
            </div>
        );
    }

    return null;
}

export default InteractionBanner;
