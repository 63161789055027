import React from 'react';
import './Styles/Header.css';
import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { useDrag, useDrop } from 'react-dnd';

interface HeaderProps {
    source: Source;
    onToggle: (source: Source, checked: boolean) => void;
    isChecked: boolean;
    sourceOrder: string[];
    handleReorder: (draggedUsername: string, hoveredUsername: string) => void;
}

export const Header = ({ source, onToggle, isChecked, sourceOrder, handleReorder }: HeaderProps) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onToggle(source, event.target.checked);
    };

    const [{ isDragging }, dragRef] = useDrag({
        type: 'SOURCE',
        item: { username: source.username },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: 'SOURCE',
        hover: (draggedItem: { username: string }) => {
            if (draggedItem.username !== source.username) {
                handleReorder(draggedItem.username, source.username);
            }
        },
    });

    return (
        <section>
            <div ref={(node) => dragRef(dropRef(node))} className={`dyn-inputs ${isDragging ? 'dragging' : ''}`} style={{ opacity: isDragging ? 0.5 : 1 }}>
                <label className={`custom-checkbox ${isChecked ? 'checked' : ''}`}>
                    <span className='label-text'>{source.username}</span>
                    <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
                    <span className='custom-checkbox-box'>
                        <span className='checkmark'>✔</span>
                        <span className='cross'>✖</span>
                    </span>
                </label>
            </div>
        </section>
    );
};

export default Header;
