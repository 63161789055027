import axios from 'axios';
import { Source, Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { Api } from '@sodamnfoolish/sjc-backend-types';

export class ParserBackend {
    constructor(private readonly url: string, private readonly apiKey: string) {}

    public getSources = (): Promise<Source[]> =>
        axios
            .get(`${this.url}/feed/sources`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            })
            .then((res) => res.data);

    public getFeed = (sources: Source[], size = 20): Promise<Post[]> =>
        axios
            .get(`${this.url}/feed/`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                params: {
                    sources: JSON.stringify(sources),
                    size: size,
                },
            })
            .then((res) => {
                return res.data;
            });

    public getMedia = (mediaId: string): Promise<Blob> =>
        axios
            .get(`${this.url}/feed/media/${mediaId}`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                responseType: 'blob',
            })
            .then((res) => res.data);

    public getVanity = (): Promise<Array<number>> =>
        axios
            .get(`${this.url}/pump/vanity`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            })
            .then((res) => res.data);

    public getChatGptMetadataFromPostId = (postId: string): Promise<Api.AI.DTO.Metadata> =>
        axios
            .post(
                `${this.url}/ai/generate-token-metadata-by-post-id/${postId}`,
                {},
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            )
            .then((res) => res.data);

    public generateTokenMetadataByTweetUrl = (encodedTweetUrl: string): Promise<Api.AI.DTO.Metadata> =>
        axios
            .post(
                `${this.url}/ai/generate-token-metadata-by-tweet-url/${encodedTweetUrl}`,
                {},
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            )
            .then((res) => res.data);

    public generateTokenMetadataByUrl = (ecodedUrl: string): Promise<Api.AI.DTO.Metadata> =>
        axios
            .post(
                `${this.url}/ai/generate-token-metadata-by-url/${ecodedUrl}`,
                {},
                {
                    headers: {
                        'x-api-key': this.apiKey,
                    },
                },
            )
            .then((res) => res.data);

    public getTokenMetadata = (mint: string): Promise<Api.Token.DTO.Metadata> =>
        axios
            .get(`${this.url}/token/${mint}/fetch`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            })
            .then((res) => res.data);

    public deployToken = (deployRequest: Api.Wallet.DTO.DeployRequest, wallet: string): Promise<Api.Wallet.DTO.DeployResponse> =>
        axios
            .post(`${this.url}/wallet/deploy`, deployRequest, {
                headers: {
                    'x-api-key': this.apiKey,
                    'wallet-public-key': wallet,
                },
            })
            .then((res) => res.data);

    public sellToken = (sellRequest: Api.Wallet.DTO.SellRequest, wallet: string): Promise<Api.Wallet.DTO.Signature> =>
        axios
            .post(`${this.url}/wallet/sell`, sellRequest, {
                headers: {
                    'x-api-key': this.apiKey,
                    'wallet-public-key': wallet,
                },
            })
            .then((res) => res.data);

    public transferToken = (transferRequest: Api.Wallet.DTO.TransferRequest, wallet: string): Promise<Api.Wallet.DTO.Signature> =>
        axios
            .post(`${this.url}/wallet/transfer`, transferRequest, {
                headers: {
                    'x-api-key': this.apiKey,
                    'wallet-public-key': wallet,
                },
            })
            .then((res) => res.data);
}
