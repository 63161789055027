import { useEffect, useState } from 'react';
import { useTelegram } from './UseTelegram';
import './Telegram.css';
import { toast } from 'react-toastify';

interface RegisterChannelProps {
    telegramChannelUsername: string;
    setTelegramChannelUsername: (username: string) => void;
    autofillValue: string;
    commentsAboveCode: string;
    setCommentsAboveCode: (comments: string) => void;
    preventSymbols: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const RegisterChannel: React.FC<RegisterChannelProps> = ({
    telegramChannelUsername,
    setTelegramChannelUsername,
    autofillValue,
    commentsAboveCode,
    setCommentsAboveCode,
    preventSymbols,
}) => {
    const { createChannel, resolveUsername, updateUsername, setAdmin, checkUsername } = useTelegram();
    const [telegramChannelUsernameAvailable, setTelegramChannelUsernameAvailable] = useState<boolean>(false);
    const [telegramChannelTitle, setTelegramChannelTitle] = useState<string>('');
    const [telegramChannelAbout, setTelegramChannelAbout] = useState<string>('');
    const [inviteLinks, setInviteLinks] = useState<string[]>([]);
    const [copyUsername, setCopyUsername] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [adminValue, setAdminValue] = useState<string>(() => localStorage.getItem('adminValue') || '');

    const adminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAdminValue(value);
        localStorage.setItem('adminValue', value);
    };

    const onTelegramChannelUsernameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelegramChannelUsernameAvailable(false);
        const username = event.target.value;
        setTelegramChannelUsername(username);

        if (copyUsername) {
            setTelegramChannelTitle(username);
            setTelegramChannelAbout(username);
        }

        try {
            const isAvailable = await checkUsername(username);
            setTelegramChannelUsernameAvailable(isAvailable);
        } catch (error) {
            console.log(error);
        }
    };

    const copyUsernameChange = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        if (checked) {
            setTelegramChannelTitle(telegramChannelUsername);
            setTelegramChannelAbout(telegramChannelUsername);
        }
        setCopyUsername(checked);
    };

    const onRegisterBtnClick = async () => {
        try {
            const response: any = await createChannel(telegramChannelTitle, telegramChannelAbout);
            const userInfo: any = await resolveUsername(adminValue);
            await updateUsername(response.chats[0].id, response.chats[0].access_hash, telegramChannelUsername);

            await setAdmin(response.chats[0].id, response.chats[0].access_hash, userInfo.peer.user_id, userInfo.users[0].access_hash);

            const inviteLink = `https://t.me/${telegramChannelUsername}`;
            setInviteLinks((prev) => [...prev, inviteLink]);
            setCommentsAboveCode(commentsAboveCode.replace(/https:\/\/t\.me\/\w+/g, inviteLink));
            setIsCopied(false);
        } catch (error: any) {
            if (error.error_message) throw new Error(error.error_message);
            else throw new Error(error);
        }
    };

    useEffect(() => {
        if (autofillValue) {
            setTelegramChannelTitle(autofillValue);
            setTelegramChannelAbout(autofillValue);
            onTelegramChannelUsernameChange({
                target: {
                    value: autofillValue.replace(/[^a-zA-Z0-9_]/g, ''),
                },
            } as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    }, [autofillValue]);

    const copyToClipboard = async () => {
        const inviteLink = `https://t.me/${telegramChannelUsername}`;
        try {
            await navigator.clipboard.writeText(inviteLink);
            setIsCopied(true);
            toast.success('Copied Successfully');
        } catch (error) {
            console.error('Failed to copy to clipboard', error);
            toast.error('Failed to copy to clipboard');
        }
    };

    return (
        <section>
            <div className='registration-container'>
                <div className='tg-row-container'>
                    <div className='tg-row'>
                        <p>Admin</p>
                        <input className='admin-input' type='text' placeholder='Admin' value={adminValue} onChange={adminChange} />
                    </div>
                    <div className='tg-row'>
                        <p>Username</p>
                        <input
                            className='username-input'
                            value={telegramChannelUsername}
                            onChange={onTelegramChannelUsernameChange}
                            onKeyDown={preventSymbols}
                            placeholder='ThisIsUsername'
                        />
                        <p>Available: {telegramChannelUsernameAvailable ? '✅' : '❌'}</p>
                    </div>
                    <div className='tg-row'>
                        <p>Title</p>
                        <input
                            className='title-input'
                            value={telegramChannelTitle}
                            onChange={(e) => setTelegramChannelTitle(e.target.value)}
                            placeholder='ThisIsTitle'
                        />
                    </div>
                    <div className='tg-row'>
                        <p>About</p>
                        <input
                            className='about-input'
                            value={telegramChannelAbout}
                            onChange={(e) => setTelegramChannelAbout(e.target.value)}
                            placeholder='ThisIsAboutSection'
                        />
                    </div>

                    <div className='tg-row'>
                        <input className='copy-username-input' type='checkbox' checked={copyUsername} onChange={copyUsernameChange} />
                        <p>Copy Username to Title/About</p>
                    </div>
                </div>
                <div className='tg-button-container'>
                    <button className='button-accent' disabled={!telegramChannelUsernameAvailable} onClick={onRegisterBtnClick}>
                        Create
                    </button>
                    {inviteLinks.map((inviteLink, index) => (
                        <div key={index}>
                            <p>
                                ✅ <b>Channel Registered:</b> <br /> {inviteLink}
                            </p>
                            {index === inviteLinks.length - 1 && (
                                <button className='button-accent' onClick={copyToClipboard} disabled={isCopied}>
                                    {isCopied ? 'Copied!' : 'Copy'}
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
