import { NavLink } from 'react-router-dom';
import './Styles/Navbar.css';
import { useUrlParams } from '../Hooks/useUrlParams';

export function Navbar() {
    const { walletAddress, sessionId } = useUrlParams();
    return (
        <nav>
            <ul>
                <li>
                    <NavLink className='nav-monitor-feed-page nav-container' to={`/?walletAddress=${walletAddress}&sessionId=${sessionId}`}>
                        <span className='nav-active-effect'></span>
                        <span className='nav-icon'></span>
                        <span className='nav-item'>Monitor Feed</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className='nav-token-create nav-container' to={`/TokenCreate?walletAddress=${walletAddress}&sessionId=${sessionId}`}>
                        <span className='nav-active-effect'></span>
                        <span className='nav-icon'></span>
                        <span className='nav-item'>Token Create</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className='nav-settings nav-container' to={`/Settings?walletAddress=${walletAddress}&sessionId=${sessionId}`}>
                        <span className='nav-active-effect'></span>
                        <span className='nav-icon'></span>
                        <span className='nav-item'>Settings</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
